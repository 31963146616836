const NextI18Next = require("next-i18next").default;
const path = require("path");

module.exports = new NextI18Next({
    browserLanguageDetection: false,
    serverLanguageDetection: false,
    otherLanguages: ["ua", "ru"],
    localeSubpaths: {
        ru: "ru"
    },
    defaultLanguage: "ua",
    fallbackLng: "ua",
    localePath: path.resolve("./public/static/locales")
});
